import React, { useLayoutEffect, useState } from "react";
import { Link } from "gatsby";
import { FC } from "react";
import DarkModeToggle from "../DarkModeToggle";
import { useMediaQuery } from "react-responsive";
import { MdClose, MdMenu } from "react-icons/md";
import IconButton from "../IconButton";
import { Transition } from "@headlessui/react";
import { VscGithub } from "react-icons/vsc";

const links = [
  {
    href: "/#projects",
    label: "Projects",
  },
  {
    href: "/#about",
    label: "About me",
  },
  {
    href: "/#contact",
    label: "Contact",
  },
];

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
      >
        {!isMenuOpen && <MdMenu size={32} />}

        {isMenuOpen && <MdClose size={32} />}
      </IconButton>

      <Transition
        show={isMenuOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount
      >
        <div className="fixed inset-0 bg-black bg-opacity-40 top-16 backdrop-blur -z-10"></div>
      </Transition>

      <Transition
        show={isMenuOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount
      >
        <div className="absolute z-20 left-0 top-16 right-0 cursor-pointer bg-gray-50 dark:bg-gray-900 border-t border-t-gray-200 dark:border-t-gray-800">
          <div className="flex flex-col">
            {links.map((link, index) => (
              <Link
                to={link.href}
                key={index}
                className="p-4 font-semibold text-gray-900 hover:text-gray-400 focus:text-gray-400 active:text-black transition-colors dark:text-neutral-50 dark:hover:text-gray-400 dark:focus:text-gray-400 dark:active:text-white"
                activeClassName="text-red-500"
                aria-label={link.label}
                onClick={() => setIsMenuOpen(false)}
              >
                <span>{link.label}</span>
              </Link>
            ))}
          </div>
        </div>
      </Transition>
    </>
  );
};

const DesktopMenu = () => {
  return (
    <div className="flex flex-row">
      {links.map((link, index) => (
        <Link
          to={link.href}
          key={index}
          className="group px-2 py-6 font-semibold transition-colors"
          activeClassName="text-red-500"
          aria-label={link.label}
          draggable="false"
        >
          <span className="bg-transparent group-hover:bg-black group-active:bg-black dark:group-hover:bg-white dark:group-active:bg-white group-hover:bg-opacity-5 group-active:bg-opacity-10 dark:group-active:bg-opacity-10 dark:group-hover:bg-opacity-5 text-gray-900 group-hover:text-gray-800 group-active:text-black dark:text-neutral-50 dark:group-hover:text-gray-50 dark:group-active:text-white rounded-md px-4 py-3">
            {link.label}
          </span>
        </Link>
      ))}

      <IconButton
        href="https://github.com/Cobbex"
        aria-label="Github"
        isExternal
      >
        <VscGithub size={32} />
      </IconButton>
    </div>
  );
};

const ResponsiveMenu = () => {
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width:640px)",
  });

  useLayoutEffect(() => {
    setIsMounted(true);
  });

  if (!isMounted) {
    return <div style={{ height: isMobile ? "48px" : "72px" }}></div>;
  }

  if (isMobile) {
    return (
      <>
        <IconButton
          href="https://github.com/Cobbex"
          aria-label="Github"
          isExternal
        >
          <VscGithub size={32} />
        </IconButton>

        <DarkModeToggle />

        <MobileMenu />
      </>
    );
  }

  return (
    <>
      <DesktopMenu />

      <DarkModeToggle />
    </>
  );
};

const NavBar: FC = () => {
  return (
    <nav className="fixed shadow-lg sm:shadow-none left-0 top-0 right-0 px-4 py-2 sm:py-0 bg-gray-50 border-b border-b-gray-200 dark:bg-gray-900 dark:border-b-gray-800 z-40">
      <div className="container mx-auto flex flex-row items-center">
        <div>
          <Link to="/" aria-label="To start">
            <span
              className="text-xl font-extrabold text-red-500 hover:text-red-600 active:text-red-700 transition-colors"
              draggable="false"
            >
              Cosmos Lundell
            </span>
          </Link>
        </div>
        <div className="flex-auto"></div>
        <ResponsiveMenu />
      </div>
    </nav>
  );
};

export default NavBar;
