import React from "react";
import { Link } from "gatsby";
import { FC } from "react";

interface IButtonProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<any>, any> {
  href?: string;
  rel?: string;
  target?: string;
  isExternal?: boolean;
}

const Button: FC<IButtonProps> = ({
  children,
  isExternal = false,
  ...props
}) => {
  if (props?.href) {
    if (isExternal) {
      return (
        <a
          {...props}
          className="font-semibold px-4 py-2 hover:px-5 border-2 rounded bg-gray-800 border-gray-800 text-white hover:border-gray-700 hover:bg-gray-700 active:border-gray-600 active:bg-gray-600 transition-all dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:active:bg-gray-500 dark:active:border-gray-500"
          draggable="false"
          href={props.href}
          aria-label="Link"
          rel="noopener noreferrer nofollow"
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        {...props}
        className="font-semibold px-4 py-2 hover:px-5 border-2 rounded bg-gray-800 border-gray-800 text-white hover:border-gray-700 hover:bg-gray-700 active:border-gray-600 active:bg-gray-600 transition-all dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:active:bg-gray-500 dark:active:border-gray-500"
        draggable="false"
        to={props.href}
        aria-label="Link"
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className="font-semibold px-4 py-2 hover:px-5 border-2 rounded bg-gray-800 border-gray-800 text-white hover:border-gray-700 hover:bg-gray-700 active:border-gray-600 active:bg-gray-600 transition-all dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:active:bg-gray-500 dark:active:border-gray-500"
      {...props}
    >
      {children}
    </button>
  );
};

export default React.memo(Button);
