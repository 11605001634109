declare global {
  interface Window {
    __theme: "light" | "dark";
    __setPreferredTheme: (theme: string) => void;
    __themeListeners: any;
  }
}

export const getTheme = (): "dark" | "light" => {
  if (typeof window === "undefined") {
    return undefined;
  }

  return window.__theme;
};

export const setPreferredTheme = (theme: "light" | "dark") => {
  window.__setPreferredTheme(theme);
};

export const addThemeListener = (listener: () => void) => {
  if (typeof window === "undefined" || !window.__themeListeners) {
    return;
  }
  window.__themeListeners.push(listener);
};

export const removeThemeListener = (listener: () => void) => {
  if (typeof window === "undefined" || !window.__themeListeners) {
    return;
  }
  window.__themeListeners = window.__themeListeners.filter(
    (l) => l !== listener
  );
};
