import React from "react";
import { Link } from "gatsby";
import { FC } from "react";

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  href?: string;
  rel?: string;
  target?: string;
  isExternal?: boolean;
}

const InnerButton: FC = ({ children, ...props }) => (
  <button
    className="p-2 rounded-full text-gray-900 dark:text-gray-50 bg-black bg-opacity-0 hover:bg-opacity-5 active:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-5 dark:active:bg-opacity-10"
    {...props}
  >
    {children}
  </button>
);

const IconButton: FC<IButtonProps> = ({ children, isExternal, ...props }) => {
  if (props?.href) {
    if (isExternal) {
      return (
        <a
          draggable="false"
          href={props.href}
          rel="noopener noreferrer nofollow"
          target="_blank"
          className="items-center flex"
        >
          <InnerButton {...props}>{children}</InnerButton>
        </a>
      );
    }

    return (
      <Link
        className="items-center flex"
        draggable="false"
        to={props.href}
        aria-label="Link"
      >
        <InnerButton {...props}>{children}</InnerButton>
      </Link>
    );
  }

  return <InnerButton {...props}>{children}</InnerButton>;
};

export default React.memo(IconButton);
