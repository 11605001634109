import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { VscChevronDown } from "react-icons/vsc";

interface ICenteredHeroSectionProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  scrollArrow?: boolean;
}

const CenteredHeroSection: FC<ICenteredHeroSectionProps> = ({
  scrollArrow,
  children,
  ...props
}) => {
  return (
    <section className="px-4">
      <div
        {...props}
        className="flex flex-col justify-center min-h-screen container m-auto"
        style={{
          contentVisibility: "auto",
        }}
      >
        {children}

        {scrollArrow && (
          <Fade delay={3000}>
            <div className="select-none pointer-events-none absolute left-0 bottom-0 right-0 text-black opacity-20 text-center dark:text-white">
              <VscChevronDown size={72} className="mx-auto" />
            </div>
          </Fade>
        )}
      </div>
    </section>
  );
};

export default CenteredHeroSection;
