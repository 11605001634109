import React, { useEffect, useLayoutEffect, useState } from "react";
import { FC } from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import IconButton from "../IconButton";
import {
  addThemeListener,
  getTheme,
  removeThemeListener,
  setPreferredTheme,
} from "../../../utils/darkmode";

interface IDarkModeToggleProps {
  buttonSize?: number;
}

const DarkModeToggle: FC<IDarkModeToggleProps> = ({ buttonSize = 32 }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(getTheme() === "dark");

  const toggle = (themeOverride?: "dark" | "light") => {
    if (themeOverride) {
      setPreferredTheme(themeOverride);
      setIsDarkMode(themeOverride === "dark");

      return;
    }

    setPreferredTheme(isDarkMode ? "light" : "dark");
    setIsDarkMode(!isDarkMode);
  };

  useLayoutEffect(() => {
    setIsMounted(true);
  }, []);

  const listenerFunction = () => {
    toggle(getTheme());
  };

  useEffect(() => {
    addThemeListener(listenerFunction);

    return () => {
      removeThemeListener(listenerFunction);
    };
  }, [toggle]);

  if (!isMounted) {
    return (
      <IconButton
        disabled
        onClick={() => toggle()}
        aria-label="Toggle dark/light mode"
      >
        <div className="rounded-full w-8 h-8 bg-gray-900 dark:bg-gray-100" />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={() => toggle()} aria-label="Toggle dark/light mode">
      {isDarkMode && (
        <MdDarkMode
          size={buttonSize}
          className="text-gray-900 dark:text-gray-100"
        />
      )}

      {!isDarkMode && (
        <MdLightMode
          size={buttonSize}
          className="text-gray-900 dark:text-gray-100"
        />
      )}
    </IconButton>
  );
};

export default DarkModeToggle;
