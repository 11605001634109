import React from "react";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="border-t border-gray-200 dark:border-gray-800 py-6 text-center text-neutral-800 dark:text-gray-200 bg-gray-50 dark:bg-gray-900">
      <p>Copyright © {year} Cosmos Lundell</p>
    </footer>
  );
}
