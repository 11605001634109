import React from "react";
import NavBar from "../../elements/NavBar";
import Footer from "../../elements/Footer";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export function DefaultLayout({ children }) {
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          siteUrl
        }
      }
    }
  `);

  const meta = data.site.siteMetadata;

  return (
    <>
      <NavBar />

      <Helmet
        defaultTitle={meta.title}
        titleTemplate={meta.titleTemplate}
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />

        <meta name="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />

        <meta name="twitter:title" content={meta.title} />

        <link rel="dns-prefetch" href="//fonts.googleapis.com"></link>
        <link rel="dns-prefetch" href="//polyfill.io"></link>
        <link rel="dns-prefetch" href="//www.googletagmanager.com"></link>
        <link rel="dns-prefetch" href="//www.google-analytics.com"></link>
        <link rel="dns-prefetch" href="//analytics.google.com"></link>
        <link rel="dns-prefetch" href="//stats.g.doubleclick.net"></link>
        <link rel="dns-prefetch" href="//www.google.se"></link>
        <link rel="dns-prefetch" href="//static.hotjar.com"></link>

        <link rel="preconnect" href="//www.googletagmanager.com"></link>
        <link rel="preconnect" href="//www.google-analytics.com"></link>
      </Helmet>

      <main className="min-w-screen max-w-screen min-h-screen bg-gray-50 dark:bg-gray-900">
        {children}
      </main>
      <Footer />
    </>
  );
}
